@font-face {
  font-family: "Officina Serif";
  font-style: normal;
  font-weight: 400;
  src: url("../font/OfficinaSerifC-Book.eot?#iefix") format("embedded-opentype"), url("../font/OfficinaSerifC-Book.woff") format("woff"), url("../font/OfficinaSerifC-Book.svg#Officina Serif") format("svg");
}

@font-face {
  font-family: "Officina Serif";
  font-style: normal;
  font-weight: 700;
  src: url("../font/OfficinaSerifC-Bold.eot?#iefix") format("embedded-opentype"), url("../font/OfficinaSerifC-Bold.woff") format("woff"), url("../font/OfficinaSerifC-Bold.svg#Officina Serif") format("svg");
}

@font-face {
  font-family: "Myriad Pro";
  font-style: normal;
  font-weight: 400;
  src: url("../font/MyriadPro-Regular.eot?#iefix") format("embedded-opentype"), url("../font/MyriadPro-Regular.woff") format("woff"), url("../font/MyriadPro-Regular.svg#Myriad Pro") format("svg");
}

a, .link {
  outline: none;
  color: #4cb1a1;
  background-color: transparent;
  text-decoration: none;
}

a:hover, .link:hover {
  color: #df5d49;
  text-decoration: none;
}

.link--active {
  color: #000;
}

sub, sup {
  font-size: 65%;
}

img {
  max-width: 100%;
}

img.fullwidth {
  display: block;
  width: 100%;
  height: auto;
}

strong {
  font-weight: 700;
}

h1, .h1 {
  font-size: 38px;
  font-weight: 700;
  line-height: 0.9;
  letter-spacing: -0.025em;
  margin: 33px 0 0;
}

h1:first-child, .h1:first-child {
  margin-top: 0;
}

h2, .h2 {
  font-size: 38px;
  font-weight: 700;
  line-height: 0.9;
  letter-spacing: -0.025em;
  margin: 33px 0 0;
}

h2:first-child, .h2:first-child {
  margin-top: 0;
}

h2--larger, .h2--larger {
  font-size: 51px;
  line-height: 0.94;
}

h3, .h3 {
  font-size: 28px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.025em;
  margin: 25px 0 0;
}

h3:first-child, .h3:first-child {
  margin-top: 0;
}

h3--smaller, .h3--smaller {
  font-size: 20px;
  line-height: 1.4;
}

h4, .h4 {
  font-size: 25px;
  font-weight: 700;
  line-height: 1.2;
  margin: 20px 0 0;
}

h4:first-child, .h4:first-child {
  margin-top: 0;
}

.summary {
  margin-top: 17px;
  font-size: 19px;
}

.summary:first-child {
  margin-top: 0;
}

.summary--smaller {
  color: #303030;
  font-size: 16px;
  line-height: 1.5;
  margin-top: 27px;
}

p {
  margin: 25px 0 0;
  -webkit-text-size-adjust: 100%;
}

p:first-child {
  margin-top: 0;
}

ol, ul {
  margin: 17px 0 0;
  padding: 0;
}

ol:first-child, ul:first-child {
  margin-top: 0;
}

ol li, ul li {
  margin: 0;
  padding: 0 0 0 35px;
}

ol li:first-child, ul li:first-child {
  margin-top: 0;
}

li ul, li ol {
  margin-top: 5px;
}

li ul:first-child, li ol:first-child {
  margin-top: 5px;
}

li li {
  margin-top: 0;
}

ol {
  counter-reset: counter-ol;
  padding: 0 0 0 35px;
}

ol > li {
  list-style: none;
  padding: 0;
}

ol > li:before {
  display: inline-block;
  min-width: 35px;
  margin-left: -40px;
  text-align: right;
  margin-right: 5px;
  margin-top: -5px;
  margin-bottom: -5px;
  counter-increment: counter-ol;
  content: counter(counter-ol) ".";
}

ol > li li {
  list-style: decimal;
}

ol > li li:before {
  display: none;
}

ul {
  list-style: none;
}

ul li {
  position: relative;
  text-indent: -15px;
}

ul li * {
  text-indent: 0;
}

ul li:before {
  position: relative;
  top: -1px;
  display: inline-block;
  vertical-align: middle;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 10px;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #344e5b;
  text-indent: 0;
  content: "";
}

.plus-list > li {
  text-indent: -20px;
}

.plus-list > li:before {
  top: -2px;
  width: 15px;
  height: 15px;
  text-align: center;
  margin-right: 5px;
  background-color: transparent;
  border-radius: 0;
  font-size: 26px;
  line-height: 15px;
  content: "+";
}

.plus-list.nopad-list > li {
  padding-left: 20px;
}

.nopad-list > li {
  padding-left: 0;
}

nav ul {
  margin: 0;
  padding: 0;
}

nav li {
  margin: 0;
  padding: 0;
  text-indent: 0;
}

nav li:before {
  display: none;
}

table th, table td {
  padding: 3px 10px;
}

/*

Icons

*/
.icon {
  position: relative;
  display: inline-block;
  fill: currentColor;
  width: 1em;
  height: 1em;
  letter-spacing: 0;
  line-height: 1;
}

.icon-circle {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-top: -15px;
  margin-bottom: -15px;
  height: 52px;
  width: 52px;
  line-height: 52px;
  text-align: center;
  background-color: #ddd;
  color: #fff;
  border-radius: 50%;
  font-size: 28px;
}

@media (max-width: 479px) {
  .icon-circle {
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 22px;
  }
}

.icon-circle .icon {
  vertical-align: -0.15em;
  top: -1px;
}

.icon-circle--pink {
  background-color: #de2a9c;
}

.icon-circle--green {
  background-color: #73c005;
}

.icon-circle--blue {
  background-color: #006dc2;
}

.icon-circle--marine {
  background-color: #00c097;
}

.icon-circle--inline {
  top: -3px;
  margin-right: 3px;
}

@media (max-width: 479px) {
  .icon-circle--inline {
    top: -1px;
    margin-right: 5px;
  }
}

/*

Column Layout

*/
.cols {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.cols--auto {
  table-layout: auto;
}

.cols--fullheight {
  height: 100%;
}

.cols__inner {
  display: table-row;
}

.col {
  display: table-cell;
  vertical-align: top;
  padding: 0 20px;
}

.col:first-child {
  padding-left: 0;
}

.col:last-child {
  padding-right: 0;
}

.cols-wrapper {
  margin-left: -20px;
  margin-right: -20px;
}

.cols-wrapper > .cols > .col:first-child {
  padding-left: 20px;
}

.cols-wrapper > .cols > .col:last-child {
  padding-right: 20px;
}

.cols--reverse {
  direction: rtl;
}

.cols--reverse > .col {
  direction: ltr;
}

.col--fit {
  width: 1px;
}

.col--1-4 {
  width: 25%;
}

.col--3-4 {
  width: 75%;
}

.col--1-3 {
  width: 33.33%;
}

.col--2-3 {
  width: 66.66%;
}

.col--1-2 {
  width: 50%;
}

.cols--floats {
  display: block;
  width: auto;
  margin-left: -20px;
  margin-right: -20px;
}

.cols--floats:after {
  content: "";
  display: table;
  clear: both;
}

.cols--floats > .cols__inner {
  display: block;
  float: left;
  width: 100%;
  margin-top: -20px;
  margin-bottom: -20px;
}

.cols--floats > .cols__inner > .col {
  float: left;
  display: block;
  padding: 20px;
}

.cols--floats > .cols__inner > .col--right {
  float: right;
}

.cols--more-space > .col {
  padding: 0 75px;
}

.cols--more-space > .col:first-child {
  padding-left: 0;
}

.cols--more-space > .col:last-child {
  padding-right: 0;
}

.col-neg-right {
  margin-right: -100%;
}

.cols--border-space .col {
  border-top: 1px solid;
  border-color: inherit;
  height: 109px;
  vertical-align: middle;
  padding: 10px 10px;
}

.cols--border-space .col:first-child {
  padding-left: 0;
}

.cols--border-space .col:last-child {
  padding-right: 0;
}

.cols--border-space .col--border-right {
  position: relative;
  padding-right: 15px;
}

.cols--border-space .col--border-right:before {
  position: absolute;
  top: 10px;
  right: 0;
  bottom: 10px;
  border-right: 1px solid;
  border-color: inherit;
  content: '';
}

.cols--border-space .col--border-right + .col {
  padding-left: 15px;
}

.cols--border-space .cols__inner:first-child .col {
  border-top: none;
}

/*

Form's layout

*/
.form {
  position: relative;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.form:after {
  content: "";
  display: table;
  clear: both;
}

.form:first-child {
  margin-top: 0;
}

.form__items {
  margin-top: 25px;
}

.form__items:after {
  content: "";
  display: table;
  clear: both;
}

.form__items:first-child {
  margin-top: 0;
}

.form__item {
  position: relative;
  margin-top: 25px;
}

.form__item:after {
  content: "";
  display: table;
  clear: both;
}

.form__item:first-child {
  margin-top: 0;
}

.form__item-label {
  display: block;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3px;
  margin-left: 5px;
  margin-right: 5px;
}

.form__item-label:first-child {
  margin-top: 0;
}

.form__item-field {
  position: relative;
  margin-top: 3px;
}

.form__item-field:first-child {
  margin-top: 0;
}

.form__item-tip {
  margin-top: 5px;
  font-size: 14px;
}

.form__item-tip:first-child {
  margin-top: 0;
}

.form__actions {
  position: relative;
  margin-top: 70px;
}

.form__actions:first-child {
  margin-top: 0;
}

.form__actions--ml {
  margin-top: 40px;
}

@media (max-width: 767px) {
  .form__actions {
    margin-top: 40px;
  }
}

.form__error {
  padding: 5px 5px 0;
  font-size: 14px;
  color: #f00;
  -webkit-animation: 0.5s ease-out;
          animation: 0.5s ease-out;
  -webkit-animation-iteration-count: 2;
          animation-iteration-count: 2;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

.form__item-field--error-absolute .form__error {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
}

.form__error.active {
  -webkit-animation-name: pulsateError;
          animation-name: pulsateError;
}

@-webkit-keyframes pulsateError {
  0% {
    opacity: 1.0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1.0;
  }
}

@keyframes pulsateError {
  0% {
    opacity: 1.0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1.0;
  }
}

/*

Textfields

*/
textarea,
input,
button {
  -webkit-font-smoothing: antialiased;
  outline: none;
  line-height: 1.25;
  font-family: "Officina Serif", Georgia, "Times New Roman", serif;
}

.textfield {
  position: relative;
  z-index: 0;
  display: inline-block;
  font-family: "Officina Serif", Georgia, "Times New Roman", serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.285714285714286;
  background: #fff;
  color: #344e5b;
  padding: 11px 15px;
  height: 43px;
  width: 100%;
  border: 1px solid transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 5px;
  -webkit-appearance: none !important;
  outline: none;
  resize: none;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  -webkit-transition: color 0.2s, background-color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
  -o-transition: color 0.2s, background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
}

@media (max-width: 767px) {
  .textfield {
    padding: 8px 10px 7px;
    height: 35px;
    font-size: 14px;
  }
}

.textfield:focus {
  outline: none;
}

.textfield:invalid {
  background-color: #fff;
}

.textfield:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fff inset;
}

.textfield::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.textfield::-webkit-input-placeholder {
  color: #666;
  opacity: 1;
}

.textfield::-moz-placeholder {
  color: #666;
  opacity: 1;
}

.textfield:-moz-placeholder {
  color: #666;
  opacity: 1;
}

.textfield:-ms-input-placeholder {
  color: #666;
  opacity: 1;
}

.textfield.error {
  border-color: #f00;
}

.textfield.error + .textfield-placeholder {
  color: #f00 !important;
}

.textfield[disabled], .textfield.disabled {
  color: #000;
  background-color: #f8f8f8;
  opacity: 0.8;
  border-color: transparent;
  cursor: default;
  cursor: not-allowed;
  z-index: 2;
}

.textfield--autowidth {
  width: auto;
}

.textfield-wrapper {
  position: relative;
}

.textfield-wrapper--autowidth {
  display: inline-block;
  width: auto;
}

textarea.textfield {
  white-space: normal;
  overflow: auto;
  height: auto;
}

/*

Buttons

*/
.btn {
  position: relative;
  display: inline-block;
  outline: none;
  text-decoration: none;
  text-align: center;
  font-family: Formular, Roboto, Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 700;
  border: none;
  padding: 0 20px;
  vertical-align: middle;
  white-space: nowrap;
  line-height: 37px;
  height: 37px;
  min-width: 37px;
  border-radius: 4px;
  color: #fff !important;
  -webkit-appearance: none;
  background: #4cb1a1;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
}

@media (max-width: 767px) {
  .btn {
    font-size: 14px;
  }
}

.btn:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 4px;
  content: '';
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.btn::-moz-focus-inner {
  padding: 0 !important;
  border: 0 none !important;
}

.btn:hover {
  color: #fff !important;
  background-color: #df5d49;
  text-decoration: none;
}

.btn:active {
  text-decoration: none;
}

.btn[disabled], .btn.disabled {
  background: #aaa;
  opacity: 0.8;
  color: #666 !important;
  border-color: inherit;
  border-color: currentColor;
  cursor: default;
  cursor: not-allowed;
}

.btn + .btn {
  margin-left: 30px;
}

.btn > input[type='file'] {
  opacity: 0;
  filter: alpha(opacity=0);
  font-size: 120px;
  position: absolute;
  top: -20px;
  right: -10px;
  vertical-align: top;
  z-index: 3;
  cursor: pointer;
}

.btn.loading .btn__inner {
  opacity: 0;
}

.btn .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 40px;
  width: 40px;
  margin-top: -20px;
  margin-left: -20px;
}

.btn .loader svg path {
  fill: #000;
}

.btn--nominwidth {
  min-width: 0;
}

.btn--pseudo {
  background: transparent !important;
  border-color: transparent !important;
}

.btn--pseudo:before {
  display: none;
}

.btn--small {
  font-size: 14px;
  padding: 0 15px;
  line-height: 35px;
  height: 35px;
  min-width: 35px;
  border-radius: 7px;
}

.btn--medium {
  font-size: 16px;
  padding: 0 25px;
  line-height: 45px;
  height: 43px;
  min-width: 43px;
  border-radius: 8px;
}

.btn--thin {
  padding: 0 45px;
  line-height: 55px;
  height: 55px;
  min-width: 55px;
}

.btn--compact {
  padding-left: 20px;
  padding-right: 20px;
}

.btn--xcompact {
  padding-left: 10px;
  padding-right: 10px;
}

.btn--xxcompact {
  padding-left: 0;
  padding-right: 0;
}

.btn--link {
  color: #4cb1a1 !important;
}

.btn--for-banner:hover {
  -webkit-box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.5);
          box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.5);
}

.btn--for-light-blue {
  -webkit-box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.25);
          box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.25);
}

.btn--for-light-blue:hover {
  color: #4cb1a1 !important;
  background: #fff;
  -webkit-box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.5);
          box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.5);
}

.btn--no-hover {
  cursor: default;
}

.btn--no-hover:before {
  display: none;
}

.btn--minw-default {
  min-width: 150px;
}

.btn--fullwidth {
  width: 100%;
  margin-top: 20px;
}

.btn--fullwidth:first-child {
  margin-top: 0;
}

.btn .icon {
  vertical-align: -0.15em;
}

/*

Loaders

*/
.loader {
  text-indent: 0;
}

.loader svg {
  max-width: 100%;
  max-height: 100%;
}

/*

Expansible content

 */
.expand-it-container {
  overflow: hidden;
  max-height: 0;
  pointer-events: none;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.expand-it-container.overflow-visible {
  overflow: visible;
  max-height: 99999px !important;
}

.expand-it-container.active {
  max-height: 99999px;
  pointer-events: auto;
}

/*

Tabs

 */
.tabs-wrapper {
  position: relative;
  z-index: 0;
  margin-top: 20px;
}

.tabs-wrapper:first-child {
  margin-top: 0;
}

.tabs {
  position: relative;
  z-index: 0;
  margin-top: 20px;
  overflow: hidden;
}

.tabs:first-child {
  margin-top: 0;
}

.tabs ul {
  display: table;
  width: 100%;
  height: 100%;
  font-size: 0;
}

.tabs li {
  position: relative;
  display: table-cell;
  vertical-align: bottom;
  height: 100%;
  font-size: 16px;
}

@media (max-width: 479px) {
  .tabs li {
    font-size: 14px;
  }
}

.tabs li:first-child a {
  border-radius: 3px 0 0 3px;
}

.tabs li:first-child a:before {
  left: 0;
  border-radius: 3px 0 0 3px;
}

.tabs li:last-child a {
  border-radius: 0 3px 3px 0;
}

.tabs li:last-child a:before {
  border-radius: 0 3px 3px 0;
}

.tabs li.active {
  z-index: 1;
}

.tabs li.active a {
  color: #fff;
  background-color: #4cb1a1;
  cursor: default;
}

.tabs li.active a:before {
  border-color: #4cb1a1;
}

.tabs li.active a .btn-counter {
  color: #4cb1a1;
  background-color: #fff;
}

.tabs li.disabled {
  cursor: default;
  cursor: not-allowed;
}

.tabs li.disabled:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: block;
  content: "";
}

.tabs li.disabled a {
  color: #888;
}

.tabs a {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 10px 10px 11px;
  color: #fff !important;
  background-color: #65717d;
  text-align: center;
  text-decoration: none;
}

.tabs a:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -1px;
  border: 1px solid #9dabbb;
  content: '';
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.tabs a:hover {
  color: #fff !important;
}

.tabs a .btn-counter {
  background-color: #4cb1a1;
}

.tabs--vert {
  border-radius: 3px 0 0 3px;
  overflow: hidden;
}

@media (max-width: 1259px) {
  .tabs--vert {
    border-radius: 0;
  }
}

.tabs--vert .tabs__inner:before, .tabs--vert .tabs__inner:after {
  display: none;
}

.tabs--vert ul {
  display: block;
}

@media (max-width: 1259px) {
  .tabs--vert ul {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}

@media (max-width: 539px) {
  .tabs--vert ul {
    display: block;
    white-space: normal;
  }
}

.tabs--vert li {
  display: block;
  white-space: normal;
}

@media (max-width: 1259px) {
  .tabs--vert li {
    display: table-cell;
    vertical-align: top;
    font-size: 14px;
  }
}

@media (max-width: 539px) {
  .tabs--vert li {
    display: inline-block;
    width: 50%;
  }
}

@media (min-width: 1260px) {
  .tabs--vert li:first-child a {
    border-bottom-left-radius: 0 !important;
  }
}

.tabs--vert li:first-child a:before {
  border: none;
}

.tabs--vert li.active .tab-cicon {
  border-color: #4cb1a1;
  color: #4cb1a1;
}

.tabs--vert li.active a {
  background-color: #ebecee;
  color: #344e5b !important;
}

@media (max-width: 539px) {
  .tabs--vert li.active a {
    color: #4cb1a1 !important;
  }
}

.tabs--vert li.active a:before {
  border-top-color: #464f58;
}

@media (max-width: 1259px) {
  .tabs--vert li.active a:before {
    border-color: #ebecee !important;
  }
}

.tabs--vert a {
  background-color: #39424b;
  padding: 20px 40px 20px 20px;
  min-height: 60px;
  text-align: right;
}

@media (min-width: 1260px) {
  .tabs--vert a {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

@media (max-width: 1259px) {
  .tabs--vert a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 20px 5px 15px;
    min-height: 105px;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    text-align: center;
  }
}

@media (max-width: 539px) {
  .tabs--vert a {
    display: block;
    min-height: 0;
    background-color: transparent !important;
    text-align: left;
    padding: 10px 0 10px 35px;
  }
}

.tabs--vert a:before {
  border-color: #464f58 !important;
  border-bottom: none;
}

@media (min-width: 1260px) {
  .tabs--vert a:before {
    left: 0;
    border-width: 0;
    border-radius: 0 !important;
    border-top-width: 1px;
  }
}

@media (max-width: 1259px) {
  .tabs--vert a:before {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

@media (max-width: 539px) {
  .tabs--vert a:before {
    border: none !important;
  }
}

.tabs__scroll {
  position: relative;
  font-size: 0;
  white-space: nowrap;
  margin-bottom: -20px;
  padding-bottom: 20px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.touchevents .tabs__scroll {
  overflow-x: auto;
}

.touchevents .tabs__scroll::-webkit-scrollbar {
  display: none;
}

.tabs__scroll-inner {
  position: relative;
  display: inline-block;
  min-width: 100%;
  vertical-align: top;
  font-size: 14px;
}

.tabs__inner {
  position: relative;
}

.tabs--large li {
  font-size: 18px;
}

@media (max-width: 767px) {
  .tabs--large li {
    font-size: 16px;
  }
}

@media (max-width: 479px) {
  .tabs--large li {
    font-size: 14px;
  }
}

.tabs--large a {
  padding: 15px;
}

@media (max-width: 479px) {
  .tabs--large a {
    padding: 10px;
  }
}

.ready .tabs--w-gradients .tabs__inner:before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 20px;
  width: 30px;
  background-image: -webkit-linear-gradient(left, #fff, rgba(255, 255, 255, 0));
  background-image: -webkit-gradient(linear, left top, right top, from(#fff), to(rgba(255, 255, 255, 0)));
  background-image: -o-linear-gradient(left, #fff, rgba(255, 255, 255, 0));
  background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
  content: "";
  z-index: 2;
  pointer-events: none;
}

.ready .tabs--w-gradients .tabs__inner:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 20px;
  width: 30px;
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0), #fff);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(#fff));
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0), #fff);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
  content: "";
  z-index: 2;
  pointer-events: none;
}

.ready .tabs--w-gradients .tabs__inner.scroll-in-start:before {
  opacity: 0;
  visibility: hidden;
}

.ready .tabs--w-gradients .tabs__inner.scroll-in-end:after {
  opacity: 0;
  visibility: hidden;
}

.tabs--equals ul {
  table-layout: fixed;
}

.tabs--inline ul {
  display: block;
}

.tabs--inline li {
  display: inline-block;
}

.tabs--flex ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.tabs-item-end {
  margin-left: auto;
}

.tab-panes {
  position: relative;
  margin-top: 25px;
}

.tab-panes:first-child {
  margin-top: 0;
}

.tab-pane {
  position: absolute;
  top: -9999px;
}

.tab-pane.active {
  position: relative;
  top: 0;
}

.tab-pane--smoothly {
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 0;
  -webkit-transition: opacity 0.2s, visibility 0s 0.2s, z-index 0s 0.2s;
  -o-transition: opacity 0.2s, visibility 0s 0.2s, z-index 0s 0.2s;
  transition: opacity 0.2s, visibility 0s 0.2s, z-index 0s 0.2s;
}

.tab-pane--smoothly.active {
  opacity: 1;
  visibility: visible;
  z-index: 1;
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
}

.tab-cicon {
  float: left;
  margin: -3px 10px -3px -3px;
  height: 25px;
  width: 25px;
  border: 2px solid #fff;
  border-radius: 50%;
  color: #fff;
  line-height: 23px;
  text-align: left;
}

@media (max-width: 1259px) {
  .tab-cicon {
    display: block;
    float: none;
    margin: 0 auto 5px;
  }
}

@media (max-width: 539px) {
  .tab-cicon {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -11px;
  }
}

.tab-cicon .icon {
  vertical-align: -0.15em;
}

@media (max-width: 374px) {
  .tabs--tech-types {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (max-width: 374px) {
  .tabs--tech-types ul {
    table-layout: auto;
  }
}

@media (max-width: 374px) {
  .tabs--tech-types li:first-child a:before {
    border-left: none;
  }
}

@media (max-width: 374px) {
  .tabs--tech-types li:last-child a:before {
    border-right: none;
  }
}

@media (max-width: 374px) {
  .tabs--tech-types a {
    border-radius: 0 !important;
  }
  .tabs--tech-types a:before {
    border-radius: 0 !important;
  }
}

/*

Pager (including for JS dataTables plugin)

 */
.pager {
  font-size: 0;
  color: #344e5b;
  margin-top: 60px;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: -10px;
  text-align: center;
}

.pager:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .pager {
    margin-top: 40px;
  }
}

@media (max-width: 479px) {
  .pager {
    margin-left: -8px;
    margin-right: -8px;
    margin-top: 20px;
    text-align: left;
  }
}

.pager__item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-family: Formular;
  background: none !important;
  font-size: 20px;
  padding: 0 5px;
  min-width: 28px;
  height: 40px;
  line-height: 40px;
  color: #344e5b;
  margin: 0 5px 10px;
  border-radius: 4px;
  text-align: center;
  cursor: default;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

@media (max-width: 479px) {
  .pager__item {
    min-width: 0;
    padding: 0;
    font-size: 19px;
    margin-left: 8px;
    margin-right: 8px;
  }
}

.pager__item:hover {
  color: #4cb1a1;
}

.pager__item.active, .pager__item.current {
  cursor: default;
  pointer-events: none;
}

.pager__item.current {
  color: #df5d49;
}

.pager__item a {
  text-decoration: none;
}

.pager__item--btn {
  font-size: 15px;
  padding: 0 15px;
  min-width: 40px;
  margin-right: 20px;
  margin-left: 20px;
}

.pager__item--btn:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px solid #344e5b;
  border-radius: 4px;
  content: '';
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.pager__item--btn:hover:before {
  border-color: #4cb1a1;
}

a.pager__item {
  text-decoration: none;
  cursor: pointer;
}

/*

Sliders

 */
.swiper-slider {
  position: relative;
  margin-top: 40px;
}

.swiper-slider:first-child {
  margin-top: 0;
}

.swiper-slide {
  height: auto;
}

.swiper-slide img.swiper-lazy {
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.swiper-slide img.swiper-lazy-loaded {
  opacity: 1;
}

.swiper-slide__title {
  position: absolute;
  top: 30px;
  right: 20px;
  bottom: 0;
  left: 20px;
  font-size: 89px;
  font-weight: 700;
  line-height: 0.85;
}

.swiper-slide__title--white {
  color: #fff;
}

.swiper-lazy-preloader {
  top: 50% !important;
}

.swiper-button-prev,
.swiper-button-next {
  width: auto;
  height: auto;
  background: none;
}

.swiper-button-prev:before,
.swiper-button-next:before {
  display: block;
  border-width: 34px 28px 34px 28px;
  border-style: solid;
  border-color: transparent;
  content: '';
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  position: absolute;
  top: -15px;
  right: -15px;
  bottom: -15px;
  left: -15px;
  content: '';
}

.lt-ie9 .swiper-button-prev, .lt-ie9
.swiper-button-next {
  display: none;
}

.swiper-button-prev *,
.swiper-button-next * {
  vertical-align: middle;
}

.swiper-button-prev--small:before,
.swiper-button-next--small:before {
  border-width: 25px 20px 25px 20px;
}

.swiper-button-prev {
  left: 15px;
}

.swiper-button-prev:before {
  border-left-width: 0;
  border-right-color: rgba(238, 238, 238, 0.5);
}

.swiper-button-prev:hover:before {
  border-right-color: rgba(238, 238, 238, 0.8);
}

.swiper-button-next {
  right: 15px;
}

.swiper-button-next:before {
  border-right-width: 0;
  border-left-color: rgba(238, 238, 238, 0.5);
}

.swiper-button-next:hover:before {
  border-left-color: rgba(238, 238, 238, 0.8);
}

.swiper-button-prev--small:before,
.swiper-button-next--small:before {
  border-right-color: rgba(238, 238, 238, 0.8);
}

.swiper-button-prev--small:hover:before,
.swiper-button-next--small:hover:before {
  border-right-color: #eeeeee;
}

.swiper-slider--header {
  position: relative;
  margin-top: 0;
  background-color: rgba(0, 0, 0, 0.1);
  border-bottom: 15px solid #4cb1a1;
}

.swiper-slider--header .swiper-slide {
  height: 540px;
  background: center center no-repeat;
  background-size: cover;
}

.swiper-slider--header .swiper-slide > img {
  display: none;
}

.swiper-slider--header-inner {
  border-bottom: none;
}

html {
  -webkit-text-size-adjust: 100%;
  background: #e4e3dd;
  height: 100%;
}

body {
  position: relative;
  font-family: "Officina Serif", Georgia, "Times New Roman", serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.02em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #344e5b;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
  height: 100%;
  background: #e4e3dd;
  min-width: 1260px;
  overflow-x: auto;
}

@media (max-width: 319px) {
  body {
    overflow-x: auto;
  }
}

.notransition {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.touchevents body {
  cursor: pointer;
}

.touchevents .touch-focused {
  cursor: pointer;
}

.no-touchevents body ::-webkit-scrollbar {
  -webkit-appearance: none;
}

.no-touchevents body ::-webkit-scrollbar:vertical {
  width: 5px;
}

.no-touchevents body ::-webkit-scrollbar:horizontal {
  height: 5px;
}

.no-touchevents body ::-webkit-scrollbar-thumb {
  background-color: rgba(52, 78, 91, 0.3) !important;
  border-radius: 0;
  border: none;
}

.no-touchevents body ::-webkit-scrollbar-track {
  border-radius: 0;
  margin: 0;
  border: none;
  background-color: rgba(52, 78, 91, 0.1) !important;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.no-animation *, .no-animation *:before, .no-animation *:after {
  -webkit-animation: none !important;
          animation: none !important;
}

.no-transition *, .no-transition *:before, .no-transition *:after {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

::-moz-selection {
  background: #000;
  color: #344e5b;
  text-shadow: none;
}

::selection {
  background: #000;
  color: #344e5b;
  text-shadow: none;
}

/*
*
* Skeleton elements
*
*/
.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
}

.scrollable-x {
  overflow-y: hidden;
  overflow-x: auto;
}

.container {
  max-width: 1250px;
  padding: 0 15px;
  margin: 0 auto;
}

.container:after {
  content: "";
  display: table;
  clear: both;
}

.wrap {
  position: relative;
  float: left;
  width: 100%;
  min-height: 100%;
  background: #e4e3dd;
  overflow: hidden;
}

.wrap__inner {
  position: relative;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 155px;
}

/*

Responsive Helpers

 */
.respon-meter {
  position: absolute;
  z-index: 9999;
}

@media (max-width: 1259px) {
  .respon-meter {
    z-index: 1199;
  }
}

@media (max-width: 1023px) {
  .respon-meter {
    z-index: 1023;
  }
}

@media (max-width: 767px) {
  .respon-meter {
    z-index: 479;
  }
}

.only-for-notouch,
.only-for-touch,
.only-for-notouch-inline,
.only-for-touch-inline,
.only-for-notouch-inline-block,
.only-for-touch-inline-block {
  display: none !important;
}

.no-touchevents .only-for-notouch {
  display: block !important;
}

.no-touchevents .only-for-notouch-inline {
  display: inline !important;
}

.no-touchevents .only-for-notouch-inline-block {
  display: inline-block !important;
}

.touchevents .only-for-touch {
  display: block !important;
}

.touchevents .only-for-touch-inline {
  display: inline !important;
}

.touchevents .only-for-touch-inline-block {
  display: inline-block !important;
}

.tablet-show,
.tablet-show-inline-block,
.tablet-show-inline,
.tablet-show-table-row,
.tablet-show-table-cell {
  display: none !important;
}

@media (max-width: 1259px) {
  .tablet-hide {
    display: none !important;
  }
  .tablet-show {
    display: block !important;
  }
  .tablet-show-inline-block {
    display: inline-block !important;
  }
  .tablet-show-inline {
    display: inline !important;
  }
  .tablet-show-table-row {
    display: table-row !important;
  }
  .tablet-show-table-cell {
    display: table-cell !important;
  }
}

.tablet-small-show,
.tablet-small-show-inline-block,
.tablet-small-show-inline,
.tablet-small-show-table-row,
.tablet-small-show-table-cell {
  display: none !important;
}

@media (max-width: 1023px) {
  .tablet-small-hide {
    display: none !important;
  }
  .tablet-small-show {
    display: block !important;
  }
  .tablet-small-show-inline-block {
    display: inline-block !important;
  }
  .tablet-small-show-inline {
    display: inline !important;
  }
  .tablet-small-show-table-row {
    display: table-row !important;
  }
  .tablet-small-show-table-cell {
    display: table-cell !important;
  }
}

.mobile-show,
.mobile-show-inline-block,
.mobile-show-inline,
.mobile-show-table-cell {
  display: none !important;
}

@media (max-width: 767px) {
  .mobile-hide {
    display: none !important;
  }
  .mobile-show {
    display: block !important;
  }
  .mobile-show-inline-block {
    display: inline-block !important;
  }
  .mobile-show-inline {
    display: inline !important;
  }
  .mobile-show-table-cell {
    display: table-cell !important;
  }
}

.mobile-small-show,
.mobile-small-show-inline-block,
.mobile-small-show-inline,
.mobile-small-show-table-cell {
  display: none !important;
}

@media (max-width: 479px) {
  .mobile-small-hide {
    display: none !important;
  }
  .mobile-small-show {
    display: block !important;
  }
  .mobile-small-show-inline-block {
    display: inline-block !important;
  }
  .mobile-small-show-inline {
    display: inline !important;
  }
  .mobile-small-show-table-cell {
    display: table-cell !important;
  }
}

.mobile-xsmall-show,
.mobile-xsmall-show-inline-block,
.mobile-xsmall-show-inline,
.mobile-xsmall-show-table-cell {
  display: none !important;
}

@media (max-width: 374px) {
  .mobile-xsmall-hide {
    display: none !important;
  }
  .mobile-xsmall-show {
    display: block !important;
  }
  .mobile-xsmall-show-inline-block {
    display: inline-block !important;
  }
  .mobile-xsmall-show-inline {
    display: inline !important;
  }
  .mobile-xsmall-show-table-cell {
    display: table-cell !important;
  }
}

.mobile-xxsmall-show,
.mobile-xxsmall-show-inline-block,
.mobile-xxsmall-show-inline,
.mobile-xxsmall-show-table-cell {
  display: none !important;
}

@media (max-width: 359px) {
  .mobile-xxsmall-hide {
    display: none !important;
  }
  .mobile-xxsmall-show {
    display: block !important;
  }
  .mobile-xxsmall-show-inline-block {
    display: inline-block !important;
  }
  .mobile-xxsmall-show-inline {
    display: inline !important;
  }
  .mobile-xxsmall-show-table-cell {
    display: table-cell !important;
  }
}

.header {
  background-color: #344e5b;
  color: #fff;
}

.header a {
  color: #fff;
}

.header__grid {
  display: table;
  width: 100%;
}

.header__grid-wrapper {
  margin: 0 -15px;
}

.header__cell {
  display: table-cell;
  vertical-align: middle;
  padding: 10px 15px;
  height: 105px;
}

.header__cell:last-child {
  text-align: right;
}

.header__cell--fit {
  width: 1%;
}

.header__cell--hmenu {
  text-align: center;
}

.header__offset {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.header-line {
  height: 3px;
}

.hmenu {
  font-family: "Myriad Pro", Arial, sans-serif;
  font-size: 18px;
}

.hmenu ul {
  margin-right: -35px;
  margin-bottom: -20px;
}

.hmenu li {
  display: inline-block;
  vertical-align: top;
  margin-right: 35px;
  margin-bottom: 20px;
}

.hmenu li.active a {
  background-color: #df5d49;
}

.hmenu a {
  display: block;
  padding: 7px 10px 6px;
}

.logo {
  display: inline-block;
  vertical-align: middle;
  width: 185px;
}

.logo__image {
  padding-bottom: 35.13514%;
  background: url("../img/logo.png") center center no-repeat;
  background-size: contain;
}

.camera-icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-size: 34px;
}

.camera-icon .icon {
  vertical-align: top;
}

.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #344e5b;
  color: #fff;
  padding-bottom: 35px;
}

.footer a {
  color: #fff;
}

.footer__grid {
  display: table;
  width: 100%;
}

.footer__grid-wrapper {
  margin: 0 -15px;
}

.footer__cell {
  display: table-cell;
  vertical-align: middle;
  padding: 10px 15px;
  height: 100px;
}

.footer__cell:last-child {
  text-align: right;
}

.footer__cell--fit {
  width: 1%;
}

.footer__copyright {
  font-size: 16px;
  text-align: center;
  opacity: 0.5;
}

.social {
  margin-top: 20px;
  margin-right: -15px;
  margin-bottom: -15px;
  font-size: 0;
  letter-spacing: 0;
}

.social:first-child {
  margin-top: 0;
}

.social__item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  height: 29px;
  width: 29px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.social__item:before, .social__item:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url("../img/social-icons.png") -9999px 0 no-repeat;
  content: '';
  -webkit-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.social__item:after {
  opacity: 0;
  background-position-y: -29px;
}

.social__item:hover:after {
  opacity: 1;
}

.social__item--ok:before, .social__item--ok:after {
  background-position-x: 0;
}

.social__item--gplus:before, .social__item--gplus:after {
  background-position-x: -34px;
}

.social__item--inst:before, .social__item--inst:after {
  background-position-x: -68px;
}

.social__item--vk:before, .social__item--vk:after {
  background-position-x: -103px;
}

.social__item--fb:before, .social__item--fb:after {
  background-position-x: -136px;
}

.social__item--tw:before, .social__item--tw:after {
  background-position-x: -170px;
}

.ccm-ui li {
  margin-top: 0;
  padding-left: 0;
  text-indent: 0;
}

.ccm-ui li:before {
  display: none;
}

.section--default {
  padding: 40px 0 50px;
}

.section--green {
  background-color: #4cb1a1;
  color: #fff;
}

.section--green a {
  color: #fff;
}

.section--blue {
  background-color: #344e5b;
  color: #fff;
}

.section--blue a {
  color: #fff;
}

.section--red {
  background-color: #df5d49;
  color: #fff;
}

.section--red a {
  color: #fff;
}

.sq-braces {
  position: relative;
  margin-top: 40px;
  padding: 15px 40px 5px;
}

.sq-braces:first-child {
  margin-top: 0;
}

.sq-braces:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 34px;
  border: 15px solid #344e5b;
  border-right: none;
  content: '';
}

.sq-braces:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 34px;
  border: 15px solid #344e5b;
  border-left: none;
  content: '';
}

.sq-braces__tip {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.05em;
  text-align: center;
}

.sq-braces--block {
  margin-top: -15px;
  padding-bottom: 35px;
}

.sq-braces--block:before, .sq-braces--block:after {
  top: 25px;
}

.sq-braces--small {
  margin-top: -15px;
  padding-bottom: 35px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
}

.sq-braces--small .plus-list > li:before {
  font-size: 20px;
}

.sq-braces--small:before, .sq-braces--small:after {
  top: 25px;
  width: 18px;
  border-width: 8px;
}

.sq-braces--white:before, .sq-braces--white:after {
  border-color: #fff;
}

.atgbs {
  margin-top: 40px;
  margin-right: -45px;
}

.atgbs:first-child {
  margin-top: 0;
}

.atgbs:after {
  content: "";
  display: table;
  clear: both;
}

.atgbs__inner {
  float: left;
  width: 100%;
  margin: 0 0 -45px 0;
  font-size: 0;
  letter-spacing: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

.atgb {
  display: inline-block;
  vertical-align: top;
  width: 20%;
  padding: 0 45px 45px 0;
  font-size: 20px;
}

.atgb__inner {
  display: block;
  padding: 10px 10px 20px;
  min-height: 100%;
  background-color: #344e5b;
  color: #fff;
}

.atgb__inner a {
  color: #fff;
}

.atgb__summary {
  margin-top: 20px;
  padding: 0 5px 0 5px;
}

.atgb__summary:first-child {
  margin-top: 0;
}

.tgbs {
  margin-top: 50px;
  margin-right: -45px;
}

.tgbs:first-child {
  margin-top: 0;
}

.tgbs:after {
  content: "";
  display: table;
  clear: both;
}

.tgbs__inner {
  float: left;
  width: 100%;
  margin-bottom: -45px;
  font-size: 0;
  letter-spacing: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.tgb {
  display: inline-block;
  vertical-align: top;
  width: 33.33%;
  padding: 0 45px 45px 0;
}

.tgb--4 {
  width: 25%;
}

.tgb__inner {
  display: block;
  min-height: 100%;
  background-color: #4cb1a1;
  color: #fff;
}

.tgb__inner a {
  color: #fff;
}

.tgb__image {
  background: #344e5b center center no-repeat;
  background-size: cover;
}

.tgb__image.img-to-bg {
  padding-bottom: 61.333%;
}

.tgb__image.img-to-bg img {
  display: none;
}

.tgb__subtitle {
  margin-top: 10px;
  font-weight: 700;
}

.tgb__subtitle:first-child {
  margin-top: 0;
}

.tgb__content {
  padding: 15px 20px;
  font-size: 20px;
  letter-spacing: -0.022em;
}

.tgb__content--small {
  font-size: 16px;
}

.tgb__content--small p {
  margin-top: 15px;
}

.tgb__content--small p:first-child {
  margin-top: 0;
}

.tgb--bordered .tgb__inner {
  border: 1px solid #111;
}

.tgb--dark .tgb__inner {
  background-color: #344e5b;
}

.tgb--horz {
  width: 100%;
}

.tgb--horz .tgb__inner {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.tgb--horz .tgb__image {
  display: table-cell;
  vertical-align: middle;
  padding-bottom: 20%;
}

.tgb--horz .tgb__image--video {
  padding-bottom: 0;
}

.tgb--horz .tgb__image--video video {
  display: block;
  width: 100%;
}

.tgb--horz .tgb__content {
  display: table-cell;
  vertical-align: middle;
  padding: 45px 75px;
}

.video-play {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.2s, visibility 0s 0.2s;
  -o-transition: all 0.2s, visibility 0s 0.2s;
  transition: all 0.2s, visibility 0s 0.2s;
  cursor: pointer;
}

.video-play:before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 179px;
  width: 231px;
  background: url("../img/icon-video-play.png") center center no-repeat;
  background-size: contain;
  opacity: 0.5;
  content: '';
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.video-play:hover:before {
  opacity: 0.8;
}

.video-play.active {
  visibility: visible;
  opacity: 1;
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
}

.tgbs--feasts .tgbs__inner {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}

.tgbs--feasts .tgbs__inner .tgbs {
  margin-right: 0;
}

.tgbs--feasts .tgbs__inner .tgbs__inner {
  margin-bottom: 0;
}

.tgbs--feasts .tgb {
  min-width: 420px;
  text-align: left;
}

.tgbs--feasts .tgb__inner {
  position: relative;
}

.tgbs--feasts .tgb__inner.active:before {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #344e5b transparent transparent transparent;
  content: '';
}

.tgbs--feasts .tgb .swiper-slider {
  background-color: rgba(0, 0, 0, 0.2);
}

.intgb-wrapper {
  position: relative;
  width: 100%;
}

.intgb-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 99999px;
  visibility: hidden;
}

.intgb-container.active {
  position: relative;
  top: 0;
  visibility: visible;
}

.pnav-wrapper .sq-braces {
  margin-top: -15px;
  padding-bottom: 35px;
}

.pnav-wrapper .sq-braces:before, .pnav-wrapper .sq-braces:after {
  top: 25px;
}

.pnav {
  width: 470px;
  padding-right: 50px;
}

.pnav__list {
  margin-top: 30px;
}

.pnav__list:first-child {
  margin-top: 0;
}

.pnav__item {
  font-weight: 700;
}

.pnav__item a {
  display: block;
  padding: 5px 5px;
  color: #344e5b;
}

.pnav__item.active a {
  background-color: #df5d49;
  color: #fff;
}

.pnav__value {
  float: right;
  padding-left: 10px;
}

.pnav-tab-panes h2 {
  padding-left: 15px;
  padding-right: 15px;
}

.pnav-tab-panes .col:last-child {
  width: 40%;
}

.cols--services {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  font-size: 0;
}

.cols--services > .col {
  display: inline-block;
  vertical-align: top;
  width: 25%;
  padding: 0 10px;
  font-size: 20px;
}

.cols--services > .col .sq-braces {
  min-height: 100%;
}

.cols--services > .col:first-child {
  padding-left: 0;
}

.cols--services > .col:last-child {
  padding-right: 0;
}

.cols--services > .col .cols {
  font-size: 14px;
}

.cols--services > .col .cols > .col {
  padding: 0 10px;
}

.cols--services > .col .cols > .col:first-child {
  padding-left: 0;
}

.cols--services > .col .cols > .col:last-child {
  padding-right: 0;
}

.cols--services-2 > .col {
  width: 50%;
}

.gallery {
  margin-top: 50px;
  margin-left: -35px;
  margin-right: -35px;
  padding: 15px 0;
}

.gallery:first-child {
  margin-top: 0;
}

.gallery:after {
  content: "";
  display: table;
  clear: both;
}

.gallery__inner {
  float: left;
  width: 100%;
  margin: -30px 0;
  font-size: 0;
  letter-spacing: 0;
}

.gallery__item {
  display: inline-block;
  vertical-align: top;
  width: 25%;
  padding: 30px 35px;
}

.gallery__item-inner {
  display: block;
  background-color: #fff;
  padding: 10px;
  -webkit-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
          box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
}

.gallery__item-image.img-to-bg {
  position: relative;
  padding-bottom: 100%;
  background: #ddd center center no-repeat;
  background-size: cover;
}

.gallery__item-image.img-to-bg img {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
}

.map-contacts {
  margin-bottom: -35px;
  background-color: #fff;
}

.map-contacts iframe {
  display: block;
}

.iv-embed-list {
  margin-top: 50px;
  margin-right: -45px;
}

.iv-embed-list:first-child {
  margin-top: 0;
}

.iv-embed-list:after {
  content: "";
  display: table;
  clear: both;
}

.iv-embed-list__inner {
  float: left;
  width: 100%;
  margin-bottom: -45px;
  font-size: 0;
  letter-spacing: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.iv-embed {
  display: inline-block;
  vertical-align: top;
  width: 33.33% !important;
  padding: 0 45px 45px 0 !important;
  margin: 0 !important;
}

.iv-embed .iv-v {
  position: relative;
  padding: 0 !important;
  padding-top: 62.5% !important;
}

.iv-embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*

Common property classes

 */
.relative {
  position: relative;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.overflow-hidden {
  overflow: hidden;
}

.reset-margin {
  margin: 0;
}

.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.hidden-absolute {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

@media (max-width: 767px) {
  .text-align-left-on-mobile {
    text-align: left;
  }
}

@media (max-width: 767px) {
  .text-align-right-on-mobile {
    text-align: right;
  }
}

@media (max-width: 767px) {
  .text-align-center-on-mobile {
    text-align: center;
  }
}

.vmiddle {
  vertical-align: middle;
}

.vbottom {
  vertical-align: bottom;
}

.vbaseline {
  vertical-align: baseline;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.nowrap {
  white-space: nowrap;
}

.no-text-transform {
  text-transform: none;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.nounderline {
  text-decoration: none;
}

.line-through {
  text-decoration: line-through;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.pointer-events-none {
  pointer-events: none;
}

.color-default {
  color: #344e5b;
}

.color-active {
  color: #4cb1a1;
}

.color-active2 {
  color: #df5d49;
}

.font-0 {
  font-size: 0;
}

.font-small {
  font-size: 12px;
}

.font-smaller {
  font-size: 14px;
}

.font-normal {
  font-size: 16px;
}

.font-larger {
  font-size: 18px;
}

.font-semibold {
  font-weight: 600;
}

.mw870 {
  max-width: 870px;
}

.mw900 {
  max-width: 900px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt35 {
  margin-top: 35px;
}

.mt50 {
  margin-top: 50px;
}

.mt60 {
  margin-top: 60px;
}

/*# sourceMappingURL=style.css.map */
